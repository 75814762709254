import React from "react";
import {
  Box,
  Heading,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "grommet";
import ScaleLoader from "react-spinners/ScaleLoader";

import transactions from "./transactions.json";

const getBalance = (transactions) => {
  let balance = transactions[0].amount;
  for (let i = 1; i < transactions.length; i++) {
    balance += transactions[i].amount;
  }
  return balance.toFixed(2);
};

const getDisplayAmount = (amount) => {
  if (amount.indexOf("-") === 0) {
    return `-£${parseFloat(amount.substring(1, amount.length)).toFixed(2)}`;
  } else {
    return `£${parseFloat(amount).toFixed(2)}`;
  }
};

const createTransactions = (feedResponse) => {
  const transactions = feedResponse.map((entry, index) => {
    return {
      id: index,
      key: index,
      date: entry.date,
      amount: entry.amount,
      displayAmount: getDisplayAmount(`${entry.amount}`),
    };
  });
  return transactions;
};

function Transactions(props) {
  const appState = {
    loading: false,
    transactions: createTransactions(transactions),
    balance: getBalance(transactions),
  };

  return (
    <Box pad="none" overflow="scroll">
      <Heading level="2" margin="small">
        Balance: {!appState.loading ? `£${appState.balance}` : "..."}
      </Heading>
      <Heading level="3" margin="small">
        Transactions
      </Heading>
      <Box direction="row" justify="center">
        <ScaleLoader
          height={50}
          width={4}
          color={"#228BE6"}
          loading={appState.loading}
        />
      </Box>
      <Box pad="none">
        {appState.transactions && (
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell scope="col" border="bottom">
                  <strong>Date</strong>
                </TableCell>
                <TableCell scope="col" border="bottom">
                  <strong>Amount</strong>
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {appState.transactions &&
                appState.transactions.map((transaction, index) => (
                  <TableRow key={index}>
                    <TableCell scope="row">{transaction.date}</TableCell>
                    <TableCell>{transaction.displayAmount}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}
      </Box>
    </Box>
  );
}

export default Transactions;
