import React from "react";
import {
    Footer,
    Text
} from "grommet";

const year = new Date().getFullYear();

function EsbFooter() {
  return (
    <Footer background="brand" pad="medium" justify="center">
        <Text align="center">Copyright ESB &copy;{year}</Text>
    </Footer>
  );
}

export default EsbFooter;
