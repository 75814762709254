import React, { useState } from "react";
import { Avatar, Button, Box, Header, Heading, Grommet, Grid } from "grommet";

import Footer from "./components/Footer";
import Transactions from "./components/Transactions";

import "./App.scss";

const theme = {
  global: {
    colors: {
      brand: "#228BE6",
    },
    font: {
      family: "Roboto",
      size: "18px",
      height: "20px",
    },
  },
};
const users = { SH: 1, EH: 2 };
const rootClasses = ["", "red", "purple"];
const date = new Date();
const localeDate = date.toLocaleString().replace(",", "");
const displayDate = localeDate.substring(0, localeDate.lastIndexOf(":"));

function App() {
  const [activeUser, setActiveUser] = useState("SH");
  const [rootClass, setRootClass] = useState("red");

  const updateUser = (user) => {
    setActiveUser(user);
    setRootClass(rootClasses[users[user]]);
  };

  return (
    <div className={rootClass}>
      <Grommet theme={theme} themeMode="dark" full={true}>
        <Grid fill direction="column" rows={["auto", "flex", "auto"]}>
          <Header background="brand" pad="medium">
            <Heading level="1" margin="none">
              ESB
            </Heading>
            {activeUser &&
            <Avatar background="#bee1ff">
              <Heading level="4" margin="none">
                {activeUser}
              </Heading>
            </Avatar>
            }
          </Header>
          <Box pad="small">
            {!activeUser &&
              <Heading level="4" margin="medium">Select a user:</Heading>
            }
            {activeUser &&
              <Heading level="4" margin="small" alignSelf="end">
                {displayDate}
              </Heading>
            }
            {activeUser ? (
              <Transactions user={users[activeUser]}></Transactions>
            ) : (
              <Box margin="">
                <Button
                  margin="small"
                  onClick={() => updateUser("EH")}
                  primary
                  label="EH"
                />
                <Button
                  margin="small"
                  onClick={() => updateUser("SH")}
                  primary
                  label="SH"
                />
              </Box>
            )}
          </Box>
          <Box justify="end" direction="column">
            <Footer />
          </Box>
        </Grid>
      </Grommet>
    </div>
  );
}

export default App;
